import s3 from "../../s3Config";

const FileType = Object.freeze({
  ADMINS: "admins",
  COURSES_COVERS: "courses",
  COURSES_SECTIONS_COVERS: "courses-sections",
  ACCEPTANCE_RATES_FILE: "acceptance-rates-file",
  TYPICAL_QUESTIONS_FILES: "typical-questions-files",
});

function getFilePath(fileType) {
  switch (fileType) {
    case FileType.ADMINS:
      return "images/admins";

    case FileType.COURSES_COVERS:
      return "courses/courses-covers";

    case FileType.COURSES_SECTIONS_COVERS:
      return "courses/courses-sections-covers";

    case FileType.ACCEPTANCE_RATES_FILE:
      return "pdf-files/acceptance-files";

    case FileType.TYPICAL_QUESTIONS_FILES:
      return "pdf-files/typical-questions";

    default:
      return null;
  }
}

async function uploadFileByS3(file, fileName, fileType) {
  try {
    const filePath = `${getFilePath(fileType)}/${fileName}`;

    const params = {
      Bucket: "inbook-storage",
      Key: filePath,
      Body: file,
      ContentType: file.type,
    };

    const data = await s3.upload(params).promise();

    return { success: true, url: data.Location };
  } catch (error) {
    console.error("Error uploading file:", error.message);
    return { success: false, error: error.message };
  }
}

async function deleteFileByS3(fileName, fileType) {
  try {
    const filePath = `${getFilePath(fileType)}/${fileName}`;

    const params = {
      Bucket: "inbook-storage",
      Key: filePath,
    };
    // حذف الملف من S3
    await s3.deleteObject(params).promise();

    return true;
  } catch (error) {
    console.error("Error deleting file:", error.message);
    return false;
  }
}

export { FileType, uploadFileByS3, deleteFileByS3 };