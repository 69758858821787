// base
import React from 'react';
import './currentAdminProfile.css';
import { useParams } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updatePassword } from '../../../global/data/global_data';

// logic
import {
  GetAdminByIdAsync
} from '../admin_profile/adminProfileLogic';

import {
  GetPermissionsAsync
} from '../../../components/admins_components/admins_profiles_components/permissions/permissionsLogic';

import { 
  FileType, 
  uploadFileByS3, 
  deleteFileByS3
} from '../../../global/utils/s3/s3-functions';

import {
  setState,
  validateFullname,
  validateUsername,
  validateEmail,
  validateINT,
  validatePassword,
  saveNewPasswordInSessionStorage,
  setFullNameAsync,
  setUserNameAsync,
  setEmailAsync,
  sendEmailAsync,
  isEmailExistAsync,
  HashSHAAsync,
  SetPasswordAsync,
  setAdminProfileImageAsync,
  deleteImageAsync
} from './currentAdminProfileLogic';

// components
import Model from '../../../components/model/Model';
import MessageBox from '../../../components/message_box/MessageBox';
import ContainedButton from '../../../components/inputs/buttons/contained_button/ContainedButton';
import BaseInfoCard from '../../../components/admins_components/admins_profiles_components/base_info_card/BaseInfoCard';
import TextInput from '../../../components/inputs/text_input/TextInput'
import PasswordInput from '../../../components/inputs/password_input/PasswordInput';

// icons
import { IoIosCheckmarkCircle } from "react-icons/io";
import { MdImage } from "react-icons/md";
import { MdHideImage } from "react-icons/md";
import { TbTimelineEventFilled } from "react-icons/tb";
import { FaFileCirclePlus } from "react-icons/fa6";
import { FaFileCircleMinus } from "react-icons/fa6";
import { FaFilePen } from "react-icons/fa6";
import EditIcon from '@mui/icons-material/Edit';

export default function CurrentAdminProfile() {

  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams(); // from route.
  const adminIdFromRedux = useSelector((state) => state.currentUser.adminId);
  const adminPasswordFromRedux = useSelector((state) => state.currentUser.password);

  const [verificationSentCode, setVerificationSentCode] = useState('');
  const [adminPermissionsList, setAdminPermissionsList] = useState([]);
  const [reload, setReload] = useState(false);
  const [model, setModel] = useState({title: '', message: '', status: false});
  const [messageBox, setMessageBox] = useState({title: '', message: '', status: false});

  const [imageActionLoader, setImageActionLoader] = useState(false);
  const [editionStatus, setEditionStatus] = useState({fullname: false, username: false, email: false, password: false});

  const [admin, setAdmin] = useState({ 
    adminId: null, 
    isActive: null, 
    permissions: null, 
    description: null,
    fullName: null,
    email: null,
    userName: null,
    profileImageURL: null,
    profileImageName: null,
    isDeleted: null,
    registrationDate: null
  })

  const [newProfileData, setNewProfileData] = useState({
    fullname: {value: '', loader: false},
    username: {value: '', loader: false},
    email: {value: '', code: '', stage: 'email', loader: false},
    password: {currentPassword: '', newPassword: '', confirmNewPassword: '', stage: 'current-password', loader: false}
  });

  useEffect(() => {

    if (id && adminIdFromRedux && Number(id) !== Number(adminIdFromRedux)) {
      navigate('/login');
      return;
    }

    const loadAdminAsync = async () => {
      const adminData = await GetAdminByIdAsync(id);
      if (adminData) {
        setAdmin(adminData);
      } else {
        setAdmin({
          adminId: null,
          isActive: null,
          permissions: null,
          description: null,
          fullName: null,
          email: null,
          userName: null,
          profileImageURL: null,
          profileImageName: null,
          isDeleted: null,
          registrationDate: null,
        });
      }

      setState('fullname', {value: adminData.fullName, loader: false}, setNewProfileData);
      setState('username', {value: adminData.userName, loader: false}, setNewProfileData);
      setState('email', {value: adminData.email, code: '', stage: 'email', loader: false}, setNewProfileData);
      setState('password', {currentPassword: '', newPassword: '', confirmNewPassword: '', stage: 'current-password', loader: false}, setNewProfileData);
    };

    const loadPermissionsList = async () => {
      const adminPermissionsList = await GetPermissionsAsync(id);
      if (adminPermissionsList) {
        setAdminPermissionsList(adminPermissionsList);
      } else {
        setAdminPermissionsList([]);
      }
    };

    loadAdminAsync();
    loadPermissionsList();

    if (!admin) {
      navigate('/login');
      return;
    }

  }, [id, adminIdFromRedux, navigate, reload]);

  async function onFullNameClickHandler() {
    if (!validateFullname(newProfileData.fullname.value))
      return;

    setState('fullname', {value: newProfileData.fullname.value, loader: true}, setNewProfileData)
    await setFullNameAsync(admin.adminId, newProfileData.fullname.value, reload,
      setReload, setModel
    )
    setState('fullname', {value: newProfileData.fullname.value, loader: false}, setNewProfileData)
    
    setEditionStatus(prevState => ({
      ...prevState,
      fullname: !prevState.fullname
    }));
  }

  async function onUserNameClickHandler() {
    if (!validateUsername(newProfileData.username.value) || newProfileData.username.value === admin.userName)
      return;

    setState('username', {value: newProfileData.username.value, loader: true}, setNewProfileData)
    
    if (
        await setUserNameAsync
        (
          admin.adminId, newProfileData.username.value, reload,
          setReload, setModel
        )) 
      {
        setEditionStatus(prevState => ({
          ...prevState,
          username: !prevState.username
        }))
      }

    setState('username', {value: newProfileData.username.value, loader: false}, setNewProfileData);
  }

  async function sendEmail() {
    if (!validateEmail(newProfileData.email.value))
      return;

    setState
    (
      'email', 
      {value: newProfileData.email.value, code: newProfileData.email.code, stage: newProfileData.email.stage, loader: true},
      setNewProfileData
    );

    if (await isEmailExistAsync(newProfileData.email.value, setModel) === true) {
      setModel({
        title: 'Email Availability',
        message: 'The email already exists. Please choose a different one.',
        status: true
      });
      
      setState
      (
        'email', 
        {value: newProfileData.email.value, code: newProfileData.email.code, stage: newProfileData.email.stage, loader: false},
        setNewProfileData
      );
      
      return;
    }

    setVerificationSentCode(await sendEmailAsync(newProfileData.email.value, admin.userName, setModel));

    if (verificationSentCode !== null) {
      setState
      (
        'email', 
        {
          value: newProfileData.email.value, 
          code: newProfileData.email.code, 
          stage: 'code', 
          loader: false
        }, 
        setNewProfileData);
    
    }
    else {
      setModel({
        title: 'Email Sending Error',
        message: 'There was an error sending the email to the user. Please try again later.',
        status: true
      });

      setState
      (
        'email', 
        {value: newProfileData.email.value, code: newProfileData.email.code, stage: newProfileData.email.stage, loader: false},
        setNewProfileData
      );
    }
  }

  async function setEmail() {
    if (!validateINT(newProfileData.email.code))
      return;

    if ((newProfileData.email.code !== verificationSentCode) || verificationSentCode.length < 4) {
      setModel({
        title: 'Oops',
        message: 'The entered code does not match the code sent to the admin email.',
        status: true
      });
      return;
    }

    setState
    (
      'email', 
      {value: newProfileData.email.value, code: newProfileData.email.code, stage: newProfileData.email.stage, loader: true},
      setNewProfileData
    );

    if (await setEmailAsync(admin.adminId, newProfileData.email.value, reload, setReload, setModel)) {
      setEditionStatus(prevState => ({
        ...prevState,
        email: !prevState.email
      }));

      setState('email', {value: admin.email, code: '', stage: 'email', loader: false}, setNewProfileData);
    }

    setState
    (
      'email', 
      {value: newProfileData.email.value, code: newProfileData.email.code, stage: newProfileData.email.stage, loader: false},
      setNewProfileData
    );
  }

  async function onEmailClickHandler() {
    
    switch (newProfileData.email.stage)
    {
      case 'email':
        await sendEmail();
      break;
      case 'code':
        await setEmail();
      break;

      default:
        return;
    }
  }

  async function checkCurrentPassword() {
    if (!validatePassword(newProfileData.password.currentPassword))
      return;

    setState
    (
      'password', 
      {
        currentPassword: newProfileData.password.currentPassword,
        newPassword: newProfileData.password.newPassword, 
        confirmNewPassword: newProfileData.password.confirmNewPassword,
        stage: newProfileData.password.stage,
        loader: true
      },
      setNewProfileData
    );

    const basePassword = await HashSHAAsync(newProfileData.password.currentPassword);

    if (basePassword === null) {
      setState
      (
        'password', 
        {
          currentPassword: newProfileData.password.currentPassword,
          newPassword: newProfileData.password.newPassword, 
          confirmNewPassword: newProfileData.password.confirmNewPassword,
          stage: newProfileData.password.stage,
          loader: false
        },
        setNewProfileData
      );

      setModel({
        title: 'Oops',
        message: 'An error occurred while verifying the password. Please try again later.',
        status: true
      });

      return;
    }

    if (basePassword !== adminPasswordFromRedux) {
      setState
      (
        'password', 
        {
          currentPassword: newProfileData.password.currentPassword,
          newPassword: newProfileData.password.newPassword, 
          confirmNewPassword: newProfileData.password.confirmNewPassword,
          stage: newProfileData.password.stage,
          loader: false
        },
        setNewProfileData
      );

      setModel({
        title: 'Verification Failed',
        message: 'The entered password does not match our records. Please check your input and try again.',
        status: true
      });

      return;
    }
    else {
      setState
      (
        'password', 
        {
          currentPassword: newProfileData.password.currentPassword,
          newPassword: newProfileData.password.newPassword, 
          confirmNewPassword: newProfileData.password.confirmNewPassword,
          stage: 'new-password',
          loader: false
        },
        setNewProfileData
      );
    }

  }

  async function setNewPassword() {
    if (!validatePassword(newProfileData.password.newPassword) || !validatePassword(newProfileData.password.confirmNewPassword)) 
      return;
  
    if (newProfileData.password.newPassword !== newProfileData.password.confirmNewPassword) {
      setModel({
        title: 'Password Mismatch',
        message: 'The new password and confirmation do not match. Please make sure both fields are identical.',
        status: true
      });
      return;
    }

    setState('password', {
      currentPassword: newProfileData.password.currentPassword,
      newPassword: newProfileData.password.newPassword,
      confirmNewPassword: newProfileData.password.confirmNewPassword,
      stage: newProfileData.password.stage, 
      loader: true
    }, setNewProfileData);

    if (await SetPasswordAsync(admin.adminId, newProfileData.password.newPassword, reload, setReload, setModel)) {

      const hashedPassword = await HashSHAAsync(newProfileData.password.newPassword);
      saveNewPasswordInSessionStorage(hashedPassword);

      dispatch(updatePassword(hashedPassword));

      setState('password', {
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        stage: 'current-password', 
        loader: false
      }, setNewProfileData);

      setEditionStatus(prevState => ({
        ...prevState,
        password: !prevState.password
      }));
    }
    else {
      setState('password', {
        currentPassword: newProfileData.password.currentPassword,
        newPassword: newProfileData.password.newPassword,
        confirmNewPassword: newProfileData.password.confirmNewPassword,
        stage: newProfileData.password.stage, 
        loader: false
      }, setNewProfileData);
    }
  }

  async function onPasswordClickHandler() {
    
    switch (newProfileData.password.stage) 
    {
      case 'current-password':
        await checkCurrentPassword();
      return;

      case 'new-password':
        await setNewPassword();
      return;

      default:
        return;
    }
  }

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) 
    {
      const allowedTypes = ['image/jpeg', 'image/png', 'image/webp'];
  
      if (!allowedTypes.includes(file.type)) 
      {
        setModel
        ({
          title: 'Unsupported File Type',
          message: 'Please select an image in JPEG, PNG, or WebP format.',
          status: true
        });
        return;
      }
      
      const fileExtension = file.name.split('.').pop();
      const fileName = `${Date.now()}_${admin.userName}.${fileExtension}`;
  
      setImageActionLoader(true);
  
      try 
      {
        if (admin.profileImageName) 
        {
          await deleteFileByS3(admin.profileImageName, FileType.ADMINS);
        }
  
        const result = await uploadFileByS3(file, fileName, FileType.ADMINS);
  
        if (result?.success) 
        {
          if (await setAdminProfileImageAsync(admin.adminId, result?.url, fileName)) 
          {
            const adminData = JSON.parse(sessionStorage.getItem('currentAdmin'));
            adminData.profileImageURL = result?.url;
            adminData.profileImageName = fileName;
            sessionStorage.setItem('currentAdmin', JSON.stringify(adminData));

            setModel
            ({
              title: 'Success',
              message: 'Image uploaded successfully!',
              status: true
            });
          } 
          else 
          {
            const adminData = JSON.parse(sessionStorage.getItem('currentAdmin'));
            adminData.profileImageURL = '';
            adminData.profileImageName = '';
            sessionStorage.setItem('currentAdmin', JSON.stringify(adminData));
            await deleteFileByS3(fileName, FileType.ADMINS);
            throw new Error('تعمد خطأ لتفعيل catch');
          }
        } 
        else 
        {
          setModel
          ({
            title: 'Error',
            message: 'An error occurred during image upload. Please try again.',
            status: true
          });
        }
      } 
      catch (dbError) 
      {
        setModel
        ({
          title: 'Error',
          message: 'Failed to upload the image, please try again. Error: ' + dbError.message,
          status: true
        });
      } 
      finally 
      {
        setImageActionLoader(false);
        setReload(!reload);
      }
    } 
    else 
    {
      setModel
      ({
        title: 'No File Selected',
        message: 'Please select an image to upload.',
        status: true
      });
    }
  };

  async function onDeleteImageClickHandler() {
    if (!admin.profileImageName) return;

    try
    {
      setImageActionLoader(true);
      if (await deleteFileByS3(admin?.profileImageName, FileType.ADMINS))
      {
        if (await deleteImageAsync(admin.adminId))
        {
          const adminData = JSON.parse(sessionStorage.getItem('currentAdmin'));
          adminData.profileImageURL = '';
          adminData.profileImageName = '';
          sessionStorage.setItem('currentAdmin', JSON.stringify(adminData));

          setModel
          ({
            title: 'Success',
            message: 'Image deleted successfully!',
            status: true
          });
        }
        else 
        {
          setModel
          ({
            title: 'Error',
            message: 'Failed to delete image from the database. Please try again.',
            status: true
          });
        }
      }
      else
      {
          setModel
          ({
            title: 'Error',
            message: 'An error occurred during image delete from firebase. Please try again.',
            status: true
          });
      }
    }
    catch (dbError)
    {
      setModel
      ({
        title: 'Error',
        message: 'Failed to delete the image, please try again. Error: ' + dbError.message,
        status: true
      });
    }
    finally
    {
      setImageActionLoader(false);
      setReload(!reload);
    }
  }

  return (
    <div className='current-admin-profile-container'>
      <Model title={model.title} message={model.message} status={model.status} setStatus={setModel} />
      
      <MessageBox title={messageBox.title} message={messageBox.message} isVisable={messageBox.status} onClose={() => {setMessageBox(false)}}>
        <div className='confirm-button-container'>
          <ContainedButton title={'confirm'} onclickHandler={async() => {
            setMessageBox(false);
            await onDeleteImageClickHandler();
          }} />
        </div>
      </MessageBox>

      <div className='admin-profile-sub-container'>

        <div className='left-side'>

          <div className='base-info-container'>
            <BaseInfoCard 
              admin={admin}
            />
          </div>

          <div className='edit-fullname-container'>

            <div className='fullname-header-container'>
              <p className='fullname-header-label'>Edit Name</p>
              <div className='fullname-header-icon-container'>
                <EditIcon className='fullname-header-icon' fontSize='' onClick={() => {
                  setEditionStatus(prevState => ({
                    ...prevState,
                    fullname: !prevState.fullname
                  }));
                }} />
              </div>
            </div>

            <div className={`edit-profile-body-container ${editionStatus.fullname ? 'opened' : ''}`}>
              <TextInput 
                title={'Fullname'}
                value={newProfileData.fullname.value}
                onChangeHandler={(newValue) => {setState('fullname', {value: newValue, loader: false}, setNewProfileData)}}
                validateInput={validateFullname}
                tooltipTitle={'Fullname must be 25 characters or fewer.'}
                mandatory={true}
                onEnter={onFullNameClickHandler}
                />

              <div className='fullname-done-container'>
                <p className={`fullname-done ${newProfileData.fullname.loader ? 'loading' : ''}`} onClick={onFullNameClickHandler} >
                  Done
                </p>
                <div className={`set-fullname-loader ${newProfileData.fullname.loader ? 'active' : ''}`}></div>
              </div>

            </div>

          </div>

          <div className='edit-username-container'>

            <div className='username-header-container'>
              <p className='username-header-label'>Edit Username</p>
              <div className='username-header-icon-container'>
                <EditIcon className='username-header-icon' fontSize='' onClick={() => {
                  setEditionStatus(prevState => ({
                    ...prevState,
                    username: !prevState.username
                  }));
                }} />
              </div>
            </div>

            <div className={`edit-profile-body-container ${editionStatus.username ? 'opened' : ''}`}>
              <TextInput 
                title={'Username'}
                value={newProfileData.username.value}
                onChangeHandler={(newValue) => {setState('username', {value: newValue, loader: false}, setNewProfileData)}}
                validateInput={validateUsername}
                tooltipTitle={'The username must be unique and contain between 4 and 25 characters.'}
                mandatory={true}
                onEnter={onUserNameClickHandler}
                />

              <div className='username-done-container'>
                <p className={`username-done ${newProfileData.username.loader ? 'loading' : ''}`} onClick={onUserNameClickHandler} >
                  Done
                </p>
                <div className={`set-username-loader ${newProfileData.username.loader ? 'active' : ''}`}></div>
              </div>

            </div>

            </div>

          <div className='edit-email-container'>

            <div className='email-header-container'>
              <p className='email-header-label'>Edit Email</p>
              <div className='email-header-icon-container'>
                <EditIcon className='email-header-icon' fontSize='' onClick={() => {
                  setEditionStatus(prevState => ({
                    ...prevState,
                    email: !prevState.email
                  }));
                }} />
              </div>
            </div>

            <div className={`edit-profile-body-container edit-email-inputs-container ${editionStatus.email ? 'opened' : ''}`}>
              <div className={`email-input-container ${newProfileData.email.stage === 'email' ? 'visible' : ''}`}>
                <TextInput 
                  title={'New Email'}
                  value={newProfileData.email.value}
                  onChangeHandler={(newValue) => {setState('email', {value: newValue, code: newProfileData.email.code, stage: newProfileData.email.stage, loader: false}, setNewProfileData)}}
                  validateInput={validateEmail}
                  tooltipTitle={'The email must be between 5 and 150 characters.'}
                  mandatory={true}
                  onEnter={onEmailClickHandler}
                  />
              </div>

              <div className={`code-input-container ${newProfileData.email.stage === 'code' ? 'visible' : ''}`}>
                <TextInput 
                  title={'Code'}
                  value={newProfileData.email.code}
                  onChangeHandler={(newValue) => {setState('email', {value: newProfileData.email.value, code: newValue, stage: newProfileData.email.stage, loader: false}, setNewProfileData)}}
                  validateInput={validateINT}
                  tooltipTitle={'The code must consist of English numbers only and be 6 digits long.'}
                  mandatory={true}
                  onEnter={onEmailClickHandler}
                  />
              </div>

              <div className='email-done-container'>
                <p className={`email-done ${newProfileData.email.loader ? 'loading' : ''}`} onClick={onEmailClickHandler} >
                  {newProfileData.email.stage === 'email' ? 'Next' : 'Verify'}
                </p>
                <p className={`email-previous ${newProfileData.email.stage === 'code' ? 'visible' : ''}`} onClick={() => {
                  setState('email', {value: newProfileData.email.value, code: '', stage: 'email', loader: false}, setNewProfileData)
                }}>Previous</p>
                <div className={`set-email-loader ${newProfileData.email.loader ? 'active' : ''}`}></div>
              </div>

            </div>

          </div>

          <div className='edit-password-container'>

            <div className='password-header-container'>
              <p className='password-header-label'>Edit Password</p>
              <div className='password-header-icon-container'>
                <EditIcon className='password-header-icon' fontSize='' onClick={() => {
                  setEditionStatus(prevState => ({
                    ...prevState,
                    password: !prevState.password
                  }));
                }} />
              </div>
            </div>

            <div className={`edit-profile-body-container edit-password-inputs-container ${editionStatus.password ? 'opened' : ''}`}>
              <div className={`current-password-input-container ${newProfileData.password.stage === 'current-password' ? 'visible' : ''}`}>
                <PasswordInput 
                  title={'Current password'}
                  value={newProfileData.password.currentPassword}
                  onChangeHandler={(newValue) => {setState('password', 
                    {
                      currentPassword: newValue,
                      newPassword: newProfileData.password.newPassword, 
                      confirmNewPassword: newProfileData.password.confirmNewPassword, 
                      stage: newProfileData.password.stage, 
                      loader: false
                    }, setNewProfileData)}}
                  validateInput={validatePassword}
                  tooltipTitle={'Enter the current password (8 to 25 characters).'}
                  onEnter={onPasswordClickHandler}
                  />
              </div>

              <div className={`new-password-input-container ${newProfileData.password.stage === 'new-password' ? 'visible' : ''}`}>
                <PasswordInput 
                  title={'New password'}
                  value={newProfileData.password.newPassword}
                  onChangeHandler={(newValue) => {setState('password', 
                    {
                      currentPassword: newProfileData.password.currentPassword, 
                      newPassword: newValue, 
                      confirmNewPassword: newProfileData.password.confirmNewPassword, 
                      stage: newProfileData.password.stage,
                      loader: false
                    }, setNewProfileData)}}
                  validateInput={validatePassword}
                  tooltipTitle={'Enter the new password (8 to 25 characters).'}
                  onEnter={onPasswordClickHandler}
                  />

                <PasswordInput 
                  title={'Confirm new password'}
                  value={newProfileData.password.confirmNewPassword}
                  onChangeHandler={(newValue) => {setState('password', {
                    currentPassword: newProfileData.password.currentPassword, 
                    newPassword: newProfileData.password.newPassword, 
                    confirmNewPassword: newValue,
                    stage: newProfileData.password.stage, 
                    loader: false
                  }, setNewProfileData)}}
                  validateInput={validatePassword}
                  tooltipTitle={'Confirm the new password (8 to 25 characters).'}
                  onEnter={onPasswordClickHandler}
                  />
              </div>

              <div className='password-done-container'>
                <p className={`password-done ${newProfileData.password.loader ? 'loading' : ''}`} onClick={onPasswordClickHandler} >
                  {newProfileData.password.stage === 'current-password' ? 'Next' : 'Confirm'}
                </p>
                <p className={`password-previous ${newProfileData.password.stage === 'new-password' ? 'visible' : ''}`} onClick={() => {
                  setState('password', {
                    currentPassword: newProfileData.password.currentPassword,
                    newPassword: '',
                    confirmNewPassword: '',
                    stage: 'current-password', 
                    loader: false
                  }, setNewProfileData)
                }}>Previous</p>
                <div className={`set-password-loader ${newProfileData.password.loader ? 'active' : ''}`}></div>
              </div>

            </div>

          </div>

          <div className='permissions-container'>

            <div className='permissions-header-container'>
              <p className='permissions-header-label'>Permissions</p>
            </div>
            <div className='permissions-body-container'>
              <div className='permissions-items-container'>
              {
                admin.permissions < 0 ? 
                (
                  <p className='no-permissions'>You have full permissions.</p>
                ) 
                : 
                (adminPermissionsList.length > 0 ? (
                  adminPermissionsList.map((permission, index) => (
                      <div className='permission' key={`permission-${index}`}>
                        <IoIosCheckmarkCircle className='permission-accept-icon' fontSize='large'/>
                        <p className='permission-title'>{permission.permissionName}</p>
                      </div>
                    ))
                  ) : <p className='no-permissions'>No permissions available.</p>
                )
              }
              </div>
            </div>

          </div>

        </div>

        <div className='right-side'>

          <div className='actions-container'>

            <div className={`actions-sub-container ${imageActionLoader ? 'loading' : ''}`}>

              <p className='actions-header'>
                Profile Image
              </p>

              <div className={`actions-item image-input-container`}
                onClick={() => {
                  fileInputRef.current.click();
                }}>
                <p className='actions-status'>Set Image</p>
                <MdImage fontSize={'large'} />
                <input className='image-input' type='file' accept='image/*' onChange={async(e) => {await handleFileChange(e)}} ref={fileInputRef} />
              </div>

              <div className={`actions-item ${admin.profileImageURL ? '' : 'desabled'}`} 
                onClick={() => {
                  setMessageBox({
                    title: 'Confirm',
                    message: `Are you sure you want to delete your profile image? This action cannot be undone.`,
                    status: true
                  });
                }}>
                <p className='actions-clear-permissions'>Delete Image</p>
                <MdHideImage fontSize={'large'} />
              </div>

            </div>

            <div className={`actions-loader ${imageActionLoader ? 'active' : ''}`}></div>

          </div>

          <div className='history-container'>
            <p className='history-header'>
              History
            </p>

            <Link to={`/my-history/${admin.adminId}/Admins`}>
              <div className='history-item'>
                <p className='history'>My History</p>
                <TbTimelineEventFilled fontSize={'large'} />
              </div>
            </Link>
          </div>

          <div className='transactions-container'>

            <p className='transactions-header'>
              Transactions
            </p>
            
            <Link to={`/my-transactions/${admin.adminId}/additions`}>
              <div className='transactions-item'>
                <p className='transactions-additions'>Additions</p>
                <FaFileCirclePlus fontSize={'large'} />
              </div>
            </Link>
            
            <Link to={`/my-transactions/${admin.adminId}/deletions`}>
              <div className='transactions-item'>
                <p className='transactions-deletions'>Deletions</p>
                <FaFileCircleMinus fontSize={'large'} />
              </div>
            </Link>

            <Link to={`/my-transactions/${admin.adminId}/updates`}>
              <div className='transactions-item'>
                <p className='transactions-updates'>Updates</p>
                <FaFilePen fontSize={'large'} />
              </div>
            </Link>

          </div>

        </div>

      </div>

    </div>
  )
}
