import axios from "axios";
import config from "../../global/config";

import {
  fetchMultipleData,
  deleteData,
} from "../../global/utils/functions/apiService";

import { checkLength } from "../../global/utils/validation";

import {
  FileType,
  uploadFileByS3,
  deleteFileByS3,
} from "../../global/utils/s3/s3-functions";

const AcceptanceRatesModeEnum = Object.freeze({
  READ: "read",
  ADD: "add",
  UPDATE: "update",
});

const MessageBoxActionsEnum = Object.freeze({
  NONE: "none",
  DELETE: "delete",
});

function onChangeHandler(field, newValue, state) {
  state((prevState) => ({
    ...prevState,
    [field]: newValue,
  }));
}

function validateFileTitle(input) {
  return checkLength(1, 150, input);
}

function validateFileURL(input) {
  return input.length > 0;
}

function validateFileName(input) {
  return checkLength(1, 150, input);
}

function validateAcceptanceRateForAddNew(rate) {
  if (!validateFileTitle(rate.fileTitle)) return false;

  if (!rate.academicYearId || rate.academicYearId <= 0) return false;

  return true;
}

function validateAcceptanceRateForUpdate(rate) {
  if (!rate.fileId || rate.fileId <= 0) return false;

  if (!validateFileTitle(rate.fileTitle)) return false;

  if (!rate.academicYearId || rate.academicYearId <= 0) return false;

  return true;
}

function resetMessage(state) {
  state({
    title: "",
    message: "",
    isVisible: false,
  });
}

async function getYears() {
  const url = "/AcademicYears/GetAllAcademicYears";

  const response = await fetchMultipleData(url);

  if (response.success) {
    const years = response?.data?.Response?.data?.academicYears || [];

    return years;
  } else {
    console.log(response.message);
    return [];
  }
}

async function getAllRatesFiles() {
  const url = `/AcceptanceRatesFiles/GetAllAcceptanceRatesFiles`;

  const response = await fetchMultipleData(url);

  if (response.success) {
    const rates = response?.data?.Response?.data?.acceptanceRatesFiles || [];

    return rates;
  } else {
    console.log(response.message);
    return [];
  }
}

async function deleteAcceptanceRateFileAsync(
  rateObj,
  byAdmin,
  currentAdminPermissions,
  messageState,
  loaderState,
  reload,
  setReload,
  navigate
) {
  try {
    if (!currentAdminPermissions) {
      navigate("/login");
    } else if ((currentAdminPermissions & 134217728) !== 134217728) {
      messageState({
        title: "Access Denied",
        message:
          "You do not have sufficient permissions to perform this action.",
        isVisible: true,
      });
      return;
    }

    loaderState(true);

    if (rateObj.fileName) {
      await deleteFileByS3(rateObj.fileName, FileType.ACCEPTANCE_RATES_FILE);
    }

    const response = await deleteData(
      `/AcceptanceRatesFiles/DeleteAcceptanceRateFile/${rateObj.fileId}/${byAdmin}`
    );

    if (response.success && response.data.Response.data.response) {
      messageState({
        title: "Success",
        message: "The acceptance rate file has been deleted successfully.",
        isVisible: true,
      });
      setReload(!reload);
    } else {
      messageState({
        title: "Error",
        message:
          response.message ||
          "Failed to delete acceptance rate file. Please check your permissions and try again.",
        isVisible: true,
      });
    }
  } catch (error) {
    messageState({
      title: "Error",
      message:
        error.message ||
        "An unexpected error occurred while deleting the acceptance rate file. Please try again later.",
      isVisible: true,
    });
  } finally {
    loaderState(false);
  }
}

function validateFile(file) {
  if (!file) return false;

  const allowedTypes = ["application/pdf"];

  if (!allowedTypes.includes(file.type)) {
    return false;
  }

  return true;
}

function generateUniqueFileName(file) {
  if (!file || !file.name) {
    return null;
  }

  const fileExtension = file.name.split(".").pop().toLowerCase();
  const fileName = `${Date.now()}.${fileExtension}`;
  return fileName;
}

async function newAcceptanceRateFileAsync(
  event,
  rateObj,
  byAdmin,
  currentAdminPermissions,
  setMessage,
  loaderState
) {
  try {
    if (
      !validateAcceptanceRateForAddNew(rateObj) ||
      !validateFile(event?.target?.files[0])
    ) {
      setMessage({
        message:
          "Invalid acceptance rate file data. Please review your inputs and ensure all fields are correctly filled.",
        type: "error",
        isVisible: true,
      });
      return false;
    }

    if ((currentAdminPermissions & 134217728) !== 134217728) {
      setMessage({
        title: "Access Denied",
        message:
          "You do not have sufficient permissions to perform this action.",
        type: "error",
        isVisible: true,
      });
      return false;
    }

    loaderState(true);

    const file = event.target.files?.[0];
    if (!file) {
      setMessage({
        message: "No file selected. Please select a PDF file to upload.",
        type: "error",
        isVisible: true,
      });
      return false;
    }

    if (!validateFile(file)) {
      setMessage({
        message: "Invalid file type. Please upload a PDF file.",
        type: "error",
        isVisible: true,
      });
      return false;
    }

    const uniqueFileName = generateUniqueFileName(file);
    if (!uniqueFileName) {
      setMessage({
        message: "Failed to generate a unique file name. Please try again.",
        type: "error",
        isVisible: true,
      });
      return false;
    }

    const result = await uploadFileByS3(
      file,
      uniqueFileName,
      FileType.ACCEPTANCE_RATES_FILE
    );
    if (!result?.success) {
      setMessage({
        message: "An error occurred during file upload. Please try again.",
        type: "error",
        isVisible: true,
      });
      return false;
    } else {
      if (!validateFileURL(result?.url) || !validateFileName(uniqueFileName)) {
        await deleteFileByS3(uniqueFileName, FileType.ACCEPTANCE_RATES_FILE);
        return false;
      }
    }

    const url = `${config.apiBaseUrl}/AcceptanceRatesFiles/NewAcceptanceRateFile`;
    const response = await axios.post(url, {
      fileTitle: rateObj.fileTitle,
      fileURL: result?.url,
      fileName: uniqueFileName,
      academicYearId: rateObj.academicYearId,
      byAdmin: byAdmin,
    });

    if (response.status === 201) {
      setMessage({
        message: "Acceptance rate file inserted successfully.",
        type: "success",
        isVisible: true,
      });
      return true;
    } else {
      setMessage({
        message: "Failed to insert new acceptance rate file.",
        type: "error",
        isVisible: true,
      });
      return false;
    }
  } catch (error) {
    setMessage({
      message:
        error.message || "An error occurred while processing your request.",
      type: "error",
      isVisible: true,
    });
    return false;
  } finally {
    loaderState(false);
  }
}

async function updateAcceptanceRateFileAsync(
  event,
  rateObj,
  byAdmin,
  currentAdminPermissions,
  setMessage,
  loaderState
) {
  try {
    if (
      !validateAcceptanceRateForUpdate(rateObj) ||
      !validateFile(event?.target?.files[0])
    ) {
      setMessage({
        message:
          "Invalid acceptance rate file data. Please review your inputs and ensure all fields are correctly filled.",
        type: "error",
        isVisible: true,
      });
      return false;
    }

    if ((currentAdminPermissions & 134217728) !== 134217728) {
      setMessage({
        title: "Access Denied",
        message:
          "You do not have sufficient permissions to perform this action.",
        isVisible: true,
      });
      return false;
    }
    loaderState(true);

    const file = event.target.files?.[0];
    if (!file) {
      setMessage({
        message: "No file selected. Please select a PDF file to upload.",
        type: "error",
        isVisible: true,
      });
      return false;
    }

    if (!validateFile(file)) {
      setMessage({
        message: "Invalid file type. Please upload a PDF file.",
        type: "error",
        isVisible: true,
      });
      return false;
    }

    const uniqueFileName = generateUniqueFileName(file);
    if (!uniqueFileName) {
      setMessage({
        message: "Failed to generate a unique file name. Please try again.",
        type: "error",
        isVisible: true,
      });
      return false;
    }

    if (rateObj.fileName) {
      await deleteFileByS3(rateObj.fileName, FileType.ACCEPTANCE_RATES_FILE);
    }

    const result = await uploadFileByS3(
      file,
      uniqueFileName,
      FileType.ACCEPTANCE_RATES_FILE
    );
    if (!result?.success) {
      setMessage({
        message: "An error occurred during file upload. Please try again.",
        type: "error",
        isVisible: true,
      });
      return false;
    }

    const url = `${config.apiBaseUrl}/AcceptanceRatesFiles/UpdateAcceptanceRateFile`;

    const response = await axios.put(url, {
      fileId: rateObj.fileId,
      fileTitle: rateObj.fileTitle,
      fileURL: result?.url,
      fileName: uniqueFileName,
      academicYearId: rateObj.academicYearId,
      byAdmin: byAdmin,
    });

    if (response.status === 200) {
      return true;
    } else {
      setMessage({
        message: "Failed to update acceptance rate file.",
        type: "error",
        isVisible: true,
      });
      return false;
    }
  } catch (error) {
    setMessage({
      message:
        `${error.message}` ||
        "An error occurred while processing your request.",
      type: "error",
      isVisible: true,
    });
    return false;
  } finally {
    loaderState(false);
  }
}

export {
  AcceptanceRatesModeEnum,
  MessageBoxActionsEnum,
  onChangeHandler,
  validateFileTitle,
  resetMessage,
  getYears,
  getAllRatesFiles,
  deleteAcceptanceRateFileAsync,
  validateFile,
  newAcceptanceRateFileAsync,
  updateAcceptanceRateFileAsync,
};
