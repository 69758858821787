import axios from "axios";
import config from "../../global/config";

import {
  fetchScalarData,
  fetchScalarDataWithHeader,
  fetchMultipleData,
  patchData,
  deleteData,
  patchDataWithHeader,
} from "../../global/utils/functions/apiService";

import { checkLength } from "../../global/utils/validation";

import {
  FileType,
  uploadFileByS3,
  deleteFileByS3,
} from "../../global/utils/s3/s3-functions";

const SectionsModeEnum = Object.freeze({
  READ: "read",
  ADD: "add",
  UPDATE: "update",
});

const MessageBoxActionsEnum = Object.freeze({
  NONE: "none",
  DELETE_COVER: "delete-cover",
  DELETE: "delete",
});

function onChangeHandler(field, newValue, state) {
  state((prevState) => ({
    ...prevState,
    [field]: newValue,
  }));
}

function validateSectionName(input) {
  return checkLength(1, 250, input);
}

function validatePlaylistURL(input) {
  return input.length > 0;
}

function validateCoverURL(input) {
  return input.length > 0;
}

function validateCoverName(input) {
  return checkLength(1, 150, input);
}

function validateSectionForAddNew(section) {
  if (!validateSectionName(section.sectionName)) return false;

  if (!validatePlaylistURL(section.playlistURL)) return false;

  if (!section.courseId || section.courseId <= 0) return false;

  return true;
}

function resetMessage(state) {
  state({
    title: "",
    message: "",
    isVisible: false,
  });
}

function validateFile(file) {
  if (!file) return false;

  const allowedTypes = ["image/jpeg", "image/png", "image/webp"];

  if (!allowedTypes.includes(file.type)) {
    return false;
  }

  return true;
}

function generateUniqueFileName(file) {
  if (!file || !file.name) {
    return null;
  }

  const fileExtension = file.name.split(".").pop().toLowerCase();
  const fileName = `${Date.now()}.${fileExtension}`;
  return fileName;
}

async function GetCourseByIdAsync(courseId) {
  const url = `/Courses/GetCourseById/${courseId}`;

  const response = await fetchMultipleData(url);
  if (response.success) {
    return response.data.Response.data.course;
  } else {
    console.error(response.message);
    return null;
  }
}

async function getSectionsByCourseAsync(courseId) {
  const url = `/CoursesSections/GetCoursesSectionsByCourse/${courseId}`;

  const response = await fetchMultipleData(url);

  if (response.success) {
    const sections = response?.data?.Response?.data?.sections || [];

    return sections;
  } else {
    console.log(response.message);
    return [];
  }
}

async function setSectionCoverAsync(
  event,
  sectionObj,
  byAdmin,
  currentAdminPermissions,
  reload,
  setReload,
  setMessage,
  loaderState
) {
  try {
    if (!validateFile(event?.target?.files[0])) {
      setMessage({
        message:
          "Invalid image format. Please upload an image of type JPEG, PNG, or WebP.",
        type: "error",
        isVisible: true,
      });
      return false;
    }

    if ((currentAdminPermissions & 1073741824) !== 1073741824) {
      setMessage({
        title: "Access Denied",
        message:
          "You do not have sufficient permissions to perform this action.",
        type: "error",
        isVisible: true,
      });
      return false;
    }

    loaderState(true);

    const file = event.target.files?.[0];
    if (!file) {
      setMessage({
        message: "No image selected. Please select an image to upload.",
        type: "error",
        isVisible: true,
      });
      return false;
    }

    const uniqueImageName = generateUniqueFileName(file);
    if (!uniqueImageName) {
      setMessage({
        message: "Failed to generate a unique image name. Please try again.",
        type: "error",
        isVisible: true,
      });
      return false;
    }

    const result = await uploadFileByS3(
      file,
      uniqueImageName,
      FileType.COURSES_SECTIONS_COVERS
    );
    if (!result?.success) {
      setMessage({
        message:
          "An error occurred while uploading the image. Please try again later.",
        type: "error",
        isVisible: true,
      });
      return false;
    } else {
      if (
        !validateCoverURL(result?.url) ||
        !validateCoverName(uniqueImageName)
      ) {
        await deleteFileByS3(uniqueImageName, FileType.COURSES_SECTIONS_COVERS);
        return false;
      }
    }

    const url = `/CoursesSections/SetCourseSectionCover`;
    const headers = {
      sectionId: sectionObj.sectionId,
      imageURL: result?.url,
      imageName: uniqueImageName,
      byAdmin: byAdmin,
    };

    const response = await patchDataWithHeader(url, { headers });

    if (response.data.Response.data.response) {
      if (sectionObj.coverName)
        await deleteFileByS3(
          sectionObj.coverName,
          FileType.COURSES_SECTIONS_COVERS
        );
      setMessage({
        message: "The course section cover has been successfully set.",
        type: "success",
        isVisible: true,
      });
      setReload(!reload);
      return true;
    } else {
      setMessage({
        message: "Failed to set the course section cover.",
        type: "error",
        isVisible: true,
      });
      return false;
    }
  } catch (error) {
    setMessage({
      message:
        error.message || "An error occurred while processing your request.",
      type: "error",
      isVisible: true,
    });
    return false;
  } finally {
    loaderState(false);
  }
}

async function deleteSectionCoverAsync(
  sectionObj,
  byAdmin,
  currentAdminPermissions,
  reload,
  setReload,
  setMessage,
  loaderState
) {
  try {
    if ((currentAdminPermissions & 1073741824) !== 1073741824) {
      setMessage({
        title: "Access Denied",
        message:
          "You do not have sufficient permissions to perform this action.",
        isVisible: true,
      });
      return;
    }

    loaderState(true);

    if (sectionObj.coverName) {
      await deleteFileByS3(
        sectionObj.coverName,
        FileType.COURSES_SECTIONS_COVERS
      );
    }

    const response = await patchData(
      `/CoursesSections/DeleteCourseSectionCover/${sectionObj.sectionId}/${byAdmin}`
    );

    if (response.data.Response?.data?.response) {
      setMessage({
        title: "Success",
        message: "The course section cover has been deleted successfully.",
        isVisible: true,
      });
      setReload(!reload);
    } else {
      setMessage({
        title: "Error",
        message:
          response.message ||
          "Failed to delete the course section cover. Please check your permissions and try again.",
        isVisible: true,
      });
    }
  } catch (error) {
    setMessage({
      title: "Error",
      message:
        error.message ||
        "An unexpected error occurred while deleting the course section cover. Please try again later.",
      isVisible: true,
    });
  } finally {
    loaderState(false);
  }
}

async function isSectionHasRelationsAsync(sectionId) {
  const url = `/CoursesSections/IsCourseSectionHasRelations/${sectionId}`;

  try {
    const response = await fetchScalarData(url);

    if (response.data.Response.data.hasRelations === true) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error("An error occurred while fetching the data:", error);
    return false;
  }
}

async function isPlaylistURLExistAsync(playlistURL) {
  const url = `/YoutubeDataApi/IsPlaylistUrlExist`;
  const headers = {
    url: playlistURL,
  };

  try {
    const response = await fetchScalarDataWithHeader(url, { headers });
    if (response.data.Response.data.response === true) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error("An error occurred while fetching the data:", error);
    return false;
  }
}

async function deleteSectionAsync(
  sectionObj,
  byAdmin,
  currentAdminPermissions,
  messageState,
  loaderState,
  reload,
  setReload,
  navigate
) {
  try {
    if (!currentAdminPermissions) {
      navigate("/login");
    } else if (
      (BigInt(currentAdminPermissions) & BigInt(2147483648)) !==
      BigInt(2147483648)
    ) {
      messageState({
        title: "Access Denied",
        message:
          "You do not have sufficient permissions to perform this action.",
        isVisible: true,
      });
      return;
    }

    loaderState(true);

    if (await isSectionHasRelationsAsync(sectionObj.sectionId)) {
      messageState({
        title: "Action Required",
        message:
          "This course section is linked to other records. Please remove the dependencies before attempting to delete it.",
        isVisible: true,
      });
      return;
    }

    if (sectionObj.coverName) {
      await deleteFileByS3(
        sectionObj.coverName,
        FileType.COURSES_SECTIONS_COVERS
      );
    }

    const response = await deleteData(
      `/CoursesSections/DeleteCourseSection/${sectionObj.sectionId}/${byAdmin}`
    );

    if (response.success && response.data.Response.data.response) {
      messageState({
        title: "Success",
        message: "The course section has been deleted successfully.",
        isVisible: true,
      });
      setReload(!reload);
    } else {
      messageState({
        title: "Error",
        message:
          response.message ||
          "Failed to delete the course section. Please check your permissions and try again.",
        isVisible: true,
      });
    }
  } catch (error) {
    messageState({
      title: "Error",
      message:
        error.message ||
        "An unexpected error occurred while deleting the course section. Please try again later.",
      isVisible: true,
    });
  } finally {
    loaderState(false);
  }
}

async function newSectionAsync(
  sectionObj,
  byAdmin,
  currentAdminPermissions,
  setMessage,
  loaderState
) {
  try {
    if (!validateSectionForAddNew(sectionObj)) {
      setMessage({
        message:
          "Invalid course section data. Please review your inputs and ensure all fields are correctly filled.",
        type: "error",
        isVisible: true,
      });
      return false;
    }

    if ((currentAdminPermissions & 1073741824) !== 1073741824) {
      setMessage({
        title: "Access Denied",
        message:
          "You do not have sufficient permissions to perform this action.",
        type: "error",
        isVisible: true,
      });
      return false;
    }

    loaderState(true);

    const isUrlValid = await isPlaylistURLExistAsync(sectionObj.playlistURL);

    if (!isUrlValid) {
      setMessage({
        message:
          "The provided playlist URL is not valid. Please check and try again.",
        type: "error",
        isVisible: true,
      });
      return false;
    }

    const url = `${config.apiBaseUrl}/CoursesSections/NewCourseSection`;
    const response = await axios.post(url, {
      sectionName: sectionObj.sectionName,
      playlistURL: sectionObj.playlistURL,
      courseId: parseInt(sectionObj.courseId, 10),
      byAdmin: byAdmin,
    });

    if (response.status === 201) {
      setMessage({
        message: "Course section inserted successfully.",
        type: "success",
        isVisible: true,
      });
      return true;
    } else {
      setMessage({
        message: "Failed to insert new course section.",
        type: "error",
        isVisible: true,
      });
      return false;
    }
  } catch (error) {
    setMessage({
      message:
        error.message || "An error occurred while processing your request.",
      type: "error",
      isVisible: true,
    });
    return false;
  } finally {
    loaderState(false);
  }
}

async function setSectionNameAsync(
  sectionObj,
  byAdmin,
  currentAdminPermissions,
  setMessage,
  loaderState
) {
  try {
    if (!validateSectionName(sectionObj.sectionName)) {
      setMessage({
        message:
          "Invalid section name. Please review your inputs and ensure all fields are correctly filled.",
        type: "error",
        isVisible: true,
      });
      return false;
    }

    if ((currentAdminPermissions & 1073741824) !== 1073741824) {
      setMessage({
        title: "Access Denied",
        message:
          "You do not have sufficient permissions to perform this action.",
        type: "error",
        isVisible: true,
      });
      return false;
    }

    loaderState(true);

    const url = `/CoursesSections/SetCourseSectionName/${sectionObj.sectionId}/${sectionObj.sectionName}/${byAdmin}`;
    const response = await patchData(url);

    if (response.success && response.data.Response.data.response) {
      return true;
    } else {
      setMessage({
        message:
          response.message ||
          "Failed to update the section name. Please check the input information and try again.",
        type: "error",
        status: true,
      });
      return false;
    }
  } catch (error) {
    setMessage({
      message:
        error.message ||
        "An unexpected error occurred. Please try again later.",
      type: "error",
      status: true,
    });
    return false;
  } finally {
    loaderState(false);
  }
}

export {
  SectionsModeEnum,
  MessageBoxActionsEnum,
  onChangeHandler,
  validateSectionName,
  validatePlaylistURL,
  resetMessage,
  validateFile,
  GetCourseByIdAsync,
  getSectionsByCourseAsync,
  setSectionCoverAsync,
  deleteSectionCoverAsync,
  deleteSectionAsync,
  newSectionAsync,
  setSectionNameAsync,
};
